import {fetchNoteHistories, fetchNoteHistoryUnifiedDiff, restore} from '@/api/noteHistories';
import {useStore} from 'vuex'

export default function useNoteHistories() {
    const store = useStore();

    const getNoteHistories = async (noteId: number | string | null = null) => {
        const teamId = store.state.currentTeam?.id
        return await fetchNoteHistories(noteId, teamId);
    }

    const getNoteHistoryUnifiedDiff = async (noteId: number | string | null = null,
                                             historyId: number) => {
        const teamId = store.state.currentTeam?.id
        return await fetchNoteHistoryUnifiedDiff(noteId, historyId, teamId);
    }

    const restoreNoteHistory = async (noteId: number | string | null = null,
                                      historyId: number) => {
        const teamId = store.state.currentTeam?.id
        return await restore(noteId, historyId, teamId);
    }

    return {
        getNoteHistories,
        getNoteHistoryUnifiedDiff,
        restoreNoteHistory
    }

}