import {NOTE_HISTORIES, NOTE_HISTORY_UNIFIED_DIFF, RESTORE} from "@/constants/api";
import axios from "axios";

export async function fetchNoteHistories(noteId: number | string | null = null,
                                         teamId: number | null = null) {
    return await axios.get(NOTE_HISTORIES, {
        params: {
            noteId: noteId,
            teamId: teamId
        }
    });
}

export async function fetchNoteHistoryUnifiedDiff(noteId: number | string | null = null,
                                                  historyId: number,
                                                  teamId: number | null = null) {
    return await axios.get(NOTE_HISTORY_UNIFIED_DIFF, {
        params: {
            noteId: noteId,
            historyId: historyId,
            teamId: teamId
        }
    });
}

export async function restore(noteId: number | string | null = null,
                              historyId: number,
                              teamId: number | null) {
    return await axios.patch(RESTORE, {
        id: noteId,
        noteHistoryId: historyId,
        teamId: teamId
    });
}